

import Cookies from 'js-cookie'
import React from 'react'

export default function getCookiesNext(name) {

  
  const cookieValue = Cookies.get(name);
  try {
    return cookieValue ? JSON.parse(cookieValue) : null;
  } catch (error) {
    console.error(`Error parsing cookie value: ${error}`);
    return null;
  }
}


export const getCookie = (cookieName) => {
  // console.log('getCookie callaed');
  
  const cookieValue = Cookies.get(cookieName);
  if (!cookieValue) {
    console.log(`${cookieName} not found`);
    return null;
  }
  return cookieValue;
};