
'use client'
import Image from "next/image";
import { useEffect } from "react";
import { getCookie } from "./_utils/cookies/getCookiesNext";
import { useRouter } from "next/navigation";
import { useSelector, useDispatch } from "react-redux";
import { UserAuthDetails } from "./_utils/redux/features/UserAuth/UserAuthAction";

export default function Page() {
  const router = useRouter();
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.userAuth);
  const is_super_admin = data.response && data.response[0]?.is_super_admin;

  useEffect(() => {
    const userId = getCookie("user_id");
    const formData = { user_id: userId };
    dispatch(UserAuthDetails({ formData }));
  }, [dispatch]);

  // useEffect(() => {
  //   const UserToken = getCookie("token");
  //   if (UserToken) {
  //     if (is_super_admin) {
  //       router.push("/dashboard/search-arbitrage");
  //     } else {
  //       router.push('/dashboard');
  //     }
  //   }
  // }, [is_super_admin, router]);

  const handleGoHome = () => {
    const UserToken = getCookie("token");
    if (UserToken) {
      if (is_super_admin) {
        router.push("/dashboard/search-arbitrage");
      } else {
        router.push('/dashboard');
      }
    } else {
      router.push('/');
    }
  };

  return (
    <div
      className="relative w-full h-screen bg-cover bg-center"
      style={{
        backgroundImage: "url(/assets/images/errorimages/bgPage.svg)",
      }}
    >
      <div className="flex items-center justify-center w-full h-full bg-black bg-opacity-50 px-5 lg:px-0">
        <div className="text-center text-white">
          <Image
            src="/assets/images/errorimages/404.svg"
            alt="404 Image"
            className="mx-auto"
            height={200}
            width={500}
          />
          <h1 className="text-4xl xl:text-6xl text-white font-bold mb-4">
            Page Not Found.
          </h1>
          <p className="text-lg mb-6">
            This page doesn't exist or was removed! We suggest you go back to the home page.
          </p>
         
          <button 
            onClick={handleGoHome}
            className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
          >
            Go Back to Home
          </button>
        </div>
      </div>
    </div>
  );
}